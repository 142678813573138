const LocalStorageService = (function () {
  var _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  }
  
  function _setToken(tokenObj) {
    localStorage.setItem('access_token',  tokenObj ? tokenObj.access_token : '');
    localStorage.setItem('refresh_token', tokenObj ? tokenObj.refresh_token: '');
  }
  function _getAccessToken() {
    return localStorage.getItem('access_token');
  }
  function _getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }
  function _clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  function _setUserInfor(userInfor) {
    localStorage.setItem('user_infor',  userInfor);
    localStorage.setItem('user_infor', userInfor);
  }
  function _getUserInfor() {
    return localStorage.getItem('user_infor');
  }

  function _clearUserInfor() {
    localStorage.removeItem('user_infor');
  }

  function _setCloseNotification(is_close) {
    localStorage.setItem('close_notification', is_close);
  }
  function _getCloseNotification() {
    return localStorage.getItem('close_notification');
  }
  function _clearCloseNotification() {
    localStorage.removeItem('close_notification');
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    setUserInfor: _setUserInfor,
    getUserInfor: _getUserInfor,
    clearUserInfor: _clearUserInfor,
    setCloseNotification: _setCloseNotification,
    getCloseNotification: _getCloseNotification,
    clearCloseNotification: _clearCloseNotification,
  }
})();
export default LocalStorageService;