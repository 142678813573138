import store from 'store'

import APIFunction from './../services';
// import LocalStorageService from "./../services/LocalStorageService";

// get user store
export const getUserStore = () => {
    return store.get('auth');
}


// get user store
export const removeUserStore = () => {
    return store.remove('auth');
}




// const localStorageService = LocalStorageService.getService();

// query Category
export const queryCategory = () => {
    return APIFunction.queryCategory().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Warehouse
export const queryWarehouse = () => {
    return APIFunction.queryWarehouse().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query  List Page And Group
export const queryListPageAndGroup = () => {
    return APIFunction.queryListPageAndGroup().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}


// create Warehouse
export const createWarehouse = (data) => {
    return APIFunction.createWarehouse(data).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const listCountry = () => {
    return APIFunction.queryCountries().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Cities
export const listCity = () => {
    return APIFunction.queryCities().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Districts
export const listDistrict = () => {
    return APIFunction.queryDistricts().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// update Districts
export const updateDistricts = (params) => {
    return APIFunction.updateDistricts(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Wards
export const queryWards = () => {
    return APIFunction.queryWards().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}


// reset Password
export const resetPassword = (username) => {
    return APIFunction.resetPassword({ username: username }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// check validate
export const checkTokenValidate = (token) => {
    return APIFunction.checkTokenValidate({ token: token }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// change Password
export const changePasswordUser = (token, password) => {
    return APIFunction.changePassword({ id: token, username: "clone", password: password }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const userRole = (params) => {
    return APIFunction.queryUserRole(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// change Password
export const changePasswordU = (old_password, new_password ,confirm_new_password) => {
    return APIFunction.changePasswordU({ old_password: old_password, new_password: new_password, confirm_new_password: confirm_new_password }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get list bill
export const listBill = (params) => {
    return APIFunction.queryBills(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}