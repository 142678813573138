export default {
  queryRouteList: '/routes',

  queryUserInfo: '/user',
  queryUserRole: 'GET /user',
  logout: '/user/logout',
  login: 'POST /auth/login',
  resetPassword: 'POST /forgot-password',
  checkTokenValidate: 'GET /forgot-password',
  changePassword: 'PUT /forgot-password/:id',
  changePasswordU: 'POST /change-password',

  queryPostList: '/posts',
  queryDashboard: '/dashboard',

  queryOrders: 'GET /customer/order',
  createOrder: 'POST /customer/order',

  queryCities: 'GET /city',
  queryDistricts: 'GET /district',

  queryCustomers: 'GET /customer',

  queryMailmans: 'GET /mailman',

  createCustomerOrder: 'POST /customer/order',
  queryCustomerOrders: 'GET /customer/order',
  exportCustomerOrder: 'GET /customer/order-export',
  exportOrderEcommList: "GET /customer/order-international-ecom-export",

  queryTracking: 'GET /customer/tracking',
  queryTrackingPL: 'GET /customer/tracking/:id',
  queryTrackingInternational: 'GET /customer/tracking-international/:id',
  exportTemplate: 'GET /customer/template-export',
  
  getReturnOrder: 'GET /customer/return-order',
  exportOrderList: 'GET /customer/order-list-export',
  exportReturnOrder: 'GET /customer/return-order-export',
  updateOrders: 'PUT /customer/return-order',

  queryBills: 'GET /customer/bill',

  queryReport: 'GET /customer/report',

  queryTrackingPartnerAPI: 'GET /partner-api/tracking/:id',
  listTrackingPartnerInternationalAPI: 'GET /partner-api/tracking-international/:id',
  queryCountries: 'GET /country',
  listPricingInternational: 'GET /pricing-international'
}
