export const ROLE_TYPE = {
  ADMIN: 'admin',
  DEFAULT: 'admin',
  DEVELOPER: 'developer',
}

export const CANCEL_REQUEST_MESSAGE = 'cancel request'

export const DEFAULT_PAGE_SIZE = 50;

export const STATUS_LIST = [
  {
    id: 1,
    name: "Tạo mới"
  },
  {
    id: 2,
    name: "Đang lấy hàng"
  },
  {
    id: 3,
    name: "Đã lấy hàng"
  },
  {
    id: 4,
    name: "Đang giao hàng"
  },
  {
    id: 5,
    name: "Giao hàng thành công"
  },
  {
    id: 6,
    name: "Hoãn giao hàng"
  },
  {
    id: 7,
    name: "Hủy giao hàng"
  },
  {
    id: 8,
    name: "Hoàn trả"
  },
  {
    id: 15,
    name: "Đang hoàn trả"
  },
  {
    id: 9,
    name: "Hoàn thành nội bộ"
  },
  {
    id: 10,
    name: "Đã đối soát"
  },
  {
    id: 11,
    name: "Chờ duyệt hoàn"
  },
  {
    id: 12,
    name: "Giao hàng 1 phần thành công"
  },
  {
    id: 13,
    name: "Hoàn trả thành công"
  },
];

export const ORDER_TYPE = [
  'Giao hàng',
  'Đổi hàng',
  'Bình thường'
];

export const REASON = [
  'Không liên lạc được/Đến nơi không gặp',
  'Khách hẹn lại',
  'Sai địa chỉ/sđt',
  'Khách từ chối',
  'Giao hàng 1 phần'
];


export const ECOMM_STATUS = [
  {
    id: 1,
    name: "Created"
  },
  {
    id: 7,
    name: "Arrived Hanoinhanh"
  },
  {
    id: 8,
    name: "Hanoinhanh Awaiting"
  },
  {
    id: 2,
    name: "In transit"
  },
  // {
  //   id: 3,
  //   name: "Arrived USPS"
  // },
  {
    id: 6,
    name: "Delivered"
  }
];


export const EPACKET_SERVICE = {
  1: 'Epacket US',
  2: 'Epacket WW',
  3: 'Epacket US Express',
  4: 'Epacket Zone 9',
  5: 'Epacket Big Size',
  6: 'Epacket Tiktok',
  7: 'Other'
}

export const EPACKET_SERVICE_LIST = [
  {id: 1, name: 'Epacket US'},
  {id: 2, name: 'Epacket WW'},
  {id: 3, name: 'Epacket US Express'},
  {id: 4, name: 'Epacket Zone 9'},
  {id: 5, name: 'Epacket Big Size'},
  {id: 6, name: 'Epacket Tiktok'},
  {id: 7, name: 'Other'},
]

export const EPACKET_STATUS = {
  1: 'Created',
  2: 'In transit',
  // 3: 'Arrived USPS',
  4: 'Cancel',
  5: 'Cleared debit',
  6: 'Delivered',
  7: 'Arrived Hanoinhanh',
  8: 'Hanoinhanh Awaiting'
}