import React from "react";

import async from "../components/Async";

import {
  Users,
  ShoppingCart,
  Printer,
  Clipboard,
  FileText,
  File,
  Layout,
  DollarSign
} from "react-feather";
import { FlightTakeoff } from "@material-ui/icons";
import LocalStorageService from "../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ChangePassword = async(() => import("../pages/auth/ChangePassword"));
const ResetPasswordSuccess = async(() => import("../pages/auth/ResetPasswordSuccess"));

const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

const NewOrder = async(() => import("../pages/order/NewOrder"));
const Pricing = async(() => import("../pages/pricing/Pricing"));
const PrintOrder = async(() => import("../pages/order/PrintOrder"));
const Tracking = async(() => import("../pages/tracking/Tracking"));
const TrackingPublic = async(() => import("../pages/tracking/TrackingPublic"));
const TrackingInternationalPublic = async(() => import("../pages/tracking/TrackingInternationalPublic"));
const IframeUspsPublic = async(() => import("../pages/tracking/IframeUspsPublic"));
const ComponentToPrint = async(() => import("../pages/order/ComponentToPrint"));
const OrderManagement = async(() => import("../pages/order_management/OrderManagement"));
const BillManagement = async(() => import("../pages/bill/BillManagement"));
const Report = async(() => import("../pages/report/Report"));
const Fba = async(() => import("../pages/fba"));

const dashboardsRoutes = {
  id: "",
  path: "/",
  component: NewOrder,
  children: null
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/change-password",
      name: "Change Password",
      component: ChangePassword
    },
    {
      path: "/auth/reset-password-success",
      name: "Reset Password Success",
      component: ResetPasswordSuccess
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const createOrderRoute = {
  id: "Tạo đơn",
  path: "/order",
  icon: <ShoppingCart />,
  children: null,
  component: NewOrder
};

const printOrderRoute = {
  id: "In vận đơn",
  path: "/print-order",
  icon: <Printer />,
  children: null,
  component: PrintOrder
}

const trackingRoute = {
  id: "Tra cứu hành trình",
  path: "/tracking",
  icon: <Clipboard />,
  children: null,
  component: Tracking
};

const trackingPublicRoute = {
  id: "Tra cứu hành trình",
  path: "/tracking-pl",
  icon: <Clipboard />,
  children: null,
  component: TrackingPublic
};

const trackingInternationalPublicRoute = {
  id: "Tra cứu hành trình",
  path: "/tracking-pl-international",
  icon: <Clipboard />,
  children: null,
  component: TrackingInternationalPublic
};


const iframeUspsPublicRoute = {
  id: "Tra cứu tracking Usps",
  path: "/tracking-usps-international",
  icon: <Clipboard />,
  children: null,
  component: IframeUspsPublic
};

const componentPrintRoute = {
  id: "",
  path: "/component-print",
  icon: <Clipboard />,
  children: null,
  component: ComponentToPrint
}


const listOrderRoute = {
  id: "Đơn hàng Epacket",
  path: "/order-management",
  icon: <FlightTakeoff />,
  children: null,
  component: OrderManagement
}

const listBillRoute = {
  id: "Đối soát",
  path: "/bill-management",
  icon: <FileText />,
  children: null,
  component: BillManagement
}

const reportRoute = {
  id: "Thống kê doanh thu",
  path: "/report",
  icon: <File />,
  children: null,
  component: Report
}

const fbaRoute = {
  id: "Hàng quốc tế khác",
  path: "/fba",
  icon: <Layout />,
  children: null,
  component: Fba
}

const pricingRoute = {
  id: "Bảng giá",
  path: "/pricing",
  icon: <DollarSign />,
  children: null,
  component: Pricing
}

export const dashboard = [
  dashboardsRoutes,
  printOrderRoute,
  createOrderRoute,
  trackingRoute,
  componentPrintRoute,
  listOrderRoute,
  listBillRoute,
  reportRoute,
  fbaRoute,
  pricingRoute
];

export const dashboardInternational = [
  createOrderRoute,
  listOrderRoute,
  listBillRoute,
  fbaRoute,
  pricingRoute
];

export const auth = [authRoutes];
export const trackingPublic = [trackingPublicRoute, trackingInternationalPublicRoute, iframeUspsPublicRoute];

export default [
  createOrderRoute,
  printOrderRoute,
  trackingRoute,
  listOrderRoute,
  listBillRoute,
  reportRoute,
  fbaRoute,
  pricingRoute
];
