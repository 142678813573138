import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { setTheme } from "../redux/actions/themeActions";

import {
  Typography,
  Drawer,
  ListItem,
  Fab as MuiFab,
  Grid,
  FormControl,
  Button as MuiButton,
  Snackbar,
  InputAdornment,
  IconButton,
  CircularProgress,
  Button
} from "@material-ui/core";
import { green } from '@material-ui/core/colors';

import { spacing } from "@material-ui/system";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { changePasswordU } from './../models/index'

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${props => props.theme.spacing(8)}px;
  bottom: ${props => props.theme.spacing(8)}px;
`;

const Wrapper = styled.div`
  width: 400px;
  overflow-x: hidden;
`;
const Heading = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.palette.common.black};
  background: ${props => props.theme.palette.common.white};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

export default class ChangePassword extends Component {
  state = {
    anchorMenu: null,
    old_password: '',
    password: '',
    confirm_password: '',
    isOpen: false,
    errorMessage: '',
    classes: '',
    showPassword: false,
    showConfirmPassword: false,
    showOldPassword: false,
    isSubmited: false,
    error: false,
    errorServer: false,
    success: false,
  };

  componentWillMount() {
    ValidatorForm.addValidationRule('isNotEqual', (value) => {
      if (this.state.password && this.state.confirm_password && (this.state.password !== this.state.confirm_password)) {
        return false;
      }
      return true;

    });

  }

  toggleMenu = event => {
    this.setState({ anchorMenu: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorMenu: null });
  };

  changePassword = () => {
    this.setState({ isSubmited: true, success: false })
    let { password, confirm_password, old_password } = this.state;
    if (password && confirm_password && old_password) {
      changePasswordU(old_password, password, confirm_password).then((res) => {
        this.setState({ isSubmited: false })
        if (res.success) {
          this.setState({ errorServer: false, success: true });
        } else {
          this.setState({ errorServer: true, success: false });
        }
      })
    }
  }

  render() {
    const { dispatch } = this.props;
    const { classes, isOpen, errorMessage, old_password, password, confirm_password,
      showPassword, showConfirmPassword, showOldPassword, isSubmited, error, errorServer, success } = this.state;
    return (
      <Wrapper>
        <Heading>Change Password</Heading>
        <Grid container style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Grid item md={12} xs={12}>
            <ValidatorForm
              ref="form"
              onSubmit={this.changePassword}
              onError={() => { this.setState({ isOpen: false, errorMessage: '' }) }}
            >

              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  label="Mật khẩu cũ *"
                  onChange={e => this.setState({ old_password: e.target.value })}
                  name="old_password"
                  value={old_password}
                  validators={['required']}
                  errorMessages={['Bắt buộc']}
                  autoFocus
                  type={showOldPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.setState({ showOldPassword: !showOldPassword })}
                          onMouseDown={() => this.setState({ showOldPassword: !showOldPassword })}
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>)
                  }}
                  inputProps={{
                    maxLength: 45,
                  }}
                />
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  label="Mật khẩu mới *"
                  onChange={e => this.setState({ password: e.target.value })}
                  name="password"
                  value={password}
                  validators={['required']}
                  errorMessages={['Bắt buộc']}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.setState({ showPassword: !showPassword })}
                          onMouseDown={() => this.setState({ showPassword: !showPassword })}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>)
                  }}
                  inputProps={{
                    maxLength: 45,
                  }}
                />
              </FormControl>

              <FormControl margin="normal" required fullWidth>
                <TextValidator
                  label="Xác nhận mật khẩu mới *"
                  onChange={e => this.setState({ confirm_password: e.target.value })}
                  name="confirm_password"
                  value={confirm_password}
                  validators={['required', 'isNotEqual']}
                  errorMessages={['Bắt buộc', 'Mật khẩu không khớp']}
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.setState({ showConfirmPassword: !showConfirmPassword })}
                          onMouseDown={() => this.setState({ showConfirmPassword: !showConfirmPassword })}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>)
                  }}
                  inputProps={{
                    maxLength: 45,
                  }}
                />
              </FormControl>

              <Button
                disabled={isSubmited}
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                mt={2}
                type="submit">
                {isSubmited && <CircularProgress style={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: 20,
                  height: 20,
                  marginTop: -12,
                  marginLeft: -70,
                }} />}
                Xác nhận
              </Button>


            </ValidatorForm>
            {
              errorServer ? <span
                style={{ color: "red"}}> Thay đổi mật khẩu thất bại.</span> : null

            }
            {
              success ?
                <span
                  style={{ color: "blue" }}> Thay đổi mật khẩu thành công.</span> : null
            }
          </Grid>
        </Grid>
      </Wrapper>
    );
  }
}

ChangePassword = connect()(ChangePassword);