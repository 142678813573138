import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { MySnackbarContentWrapper } from './MySnackbarContentWrapper';
import Alert from '@material-ui/lab/Alert';

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar as MuiToolbar,
  Snackbar,
  Typography
} from "@material-ui/core";
import LocalStorageService from "./../services/LocalStorageService";

import { Menu as MenuIcon } from "@material-ui/icons";

import UserMenu from "./UserMenu";
import { listOrder } from "../models/order";

const localStorageService = LocalStorageService.getService();

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const Toolbar = styled(MuiToolbar)`
  background-color: #FFF;
  box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
`;
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Breadcrumb = styled.div`
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  ${props => props.theme.breakpoints.up("md")} {
    display: block;
  }
  font-weight: bold;
  color: #606673;
`;

const User = styled.div`
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  ${props => props.theme.breakpoints.up("md")} {
    display: block;
  }
  font-weight: bold;
  color: #606673;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { userName: '', roleName: '', countOrder: 0, isOpen: true, isClose: false };
  }

  async componentWillMount() {
    this.getOrder();
    await setInterval(this.getOrder, 300000);
  }

  componentDidMount() {
    let userInfor = JSON.parse(localStorageService.getUserInfor());
    if (userInfor) {
      this.setState({ userName: userInfor.fullName, roleName: userInfor.roleName })
    }
    let isClose = localStorageService.getCloseNotification();
    this.setState({ isClose: isClose });
  }

  getOrder = () => {
    let params = {
      count_order: 1,
      status: 11
    };
    listOrder(params).then((res) => {
      this.setState({ countOrder: res ? res.count : 0 });
    });
  }

  handleClose = e => {
    localStorageService.setCloseNotification(true);
    let isClose = localStorageService.getCloseNotification();
    this.setState({ isClose: isClose });
  }

  render() {
    let { onDrawerToggle } = this.props;
    let { userName, roleName, isOpen, countOrder, isClose } = this.state;
    let userInfor = JSON.parse(localStorageService.getUserInfor());
    return (
      <React.Fragment>
        <AppBar position="sticky" elevation={0}>
          <Toolbar >
            <Grid container alignItems="center">
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    // color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              <Grid item>
                <Breadcrumb>
                  {/* {breadcrumb} */}
                </Breadcrumb>
              </Grid>
                <Grid item xs style={{ textAlign: "left", maxWidth: 300 }}>
                  {userInfor.type !== "international" && (
                    <Alert onClose={e => this.handleClose(e)} variant="filled" severity="warning" style={{ display: isClose != 'false' ? 'none' : '' }}>
                      <Typography style={{ fontSize: 10 }}>Bạn có {countOrder} đơn hàng cần duyệt hoàn</Typography>
                    </Alert>
                  )}
              </Grid>
              <Grid item xs style={{ textAlign: "center" }}>
                <h2 style={{ color: "#212121" }}> QUẢN LÝ ĐƠN HÀNG </h2>
              </Grid>
              {/* <Grid item xs /> */}
              <Grid item style={{ textAlign: "right" }}>
                <User>
                  {userName}
                  <UserMenu />
                </User>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment >
    );
  }
}

const mapStateToProps = (store) => {
  return {
    breadcrumb: store.breadcrumbReducer.currentBreadcrumb
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));

