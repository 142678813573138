import APIFunction from './../services';

// get list order
export const listOrder = (params) => {
    return APIFunction.queryOrders(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// create order
export const createOrder = (params) => {
    return APIFunction.createOrder(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// update list order
export const updateOrders = (params) => {
    return APIFunction.updateOrders(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}